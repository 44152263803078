import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { H4, P } from 'ui/Text';
import { AppRoutes } from 'router';
import { NftLocaleType } from 'pages/Landing/types';
import { bestNft, guideInfo, nftInfo } from '../../constants';
import {
  CardDescription,
  CardLabel,
  FullImg,
  GuideButton,
  RotatedCardFlex,
  SingleCardLabel,
  TextWrap,
  WorkArticle,
} from './style';
import { RotatedCard, RotatedCardWrap, SingleRotatedCard, SingleRotatedCardWrap } from '../../style';

type GuideProps = {
  selectedTab: number;
};

const Guides: React.FC<GuideProps> = ({ selectedTab }) => {
  const { t } = useTranslation('landing');
  const current = Object.values(guideInfo)[selectedTab];
  const [choicedNftSetup, setChoicedNftSetup] = useState<NftLocaleType>('asic');
  const currentNFt = nftInfo[choicedNftSetup];

  const currentNftLocale = `how_it_works_block.nft_info.${currentNFt.localeValue}` as const;

  const getNftInfoValues = () =>
    Object.values(nftInfo).map(({ localeValue, equipment, img }) => (
      <RotatedCard key={img} selected={localeValue === choicedNftSetup} onClick={() => setChoicedNftSetup(localeValue)}>
        <FullImg src={img} alt={localeValue} loading="lazy" />
        <CardLabel fontFamily="secondary" size="md">
          {equipment}
        </CardLabel>
      </RotatedCard>
    ));

  const nftInfoValues = getNftInfoValues();

  if (selectedTab === 0) {
    return (
      <WorkArticle>
        <TextWrap>
          <H4>{t('how_it_works_block.guide_info.best_proposals.category')}</H4>
          <P fontFamily="primary" size="md" align="left">
            {t('how_it_works_block.guide_info.best_proposals.desc')}
          </P>
          <GuideButton to={`${AppRoutes.MARKET_ALL}?group=${bestNft.localeValue}`} size="large">
            {t('how_it_works_block.view_collection')}
          </GuideButton>
        </TextWrap>
        <SingleRotatedCardWrap>
          <SingleRotatedCard>
            <FullImg src={bestNft.img} alt="Liquid Asic 500" loading="lazy" />

            <RotatedCardFlex $direction="column" $align="center">
              <SingleCardLabel fontFamily="secondary" size="md">
                {bestNft.equipment}
              </SingleCardLabel>
              <CardDescription size="sm">{bestNft.description}</CardDescription>
            </RotatedCardFlex>
          </SingleRotatedCard>
        </SingleRotatedCardWrap>
      </WorkArticle>
    );
  }

  if (selectedTab === 1) {
    return (
      <WorkArticle>
        <TextWrap>
          <H4>{t(`${currentNftLocale}.title`)}</H4>
          <P fontFamily="primary" size="md" align="left">
            {t(`${currentNftLocale}.desc`)}
          </P>
          <GuideButton to={`${AppRoutes.MARKET_ALL}?group=${choicedNftSetup}`} size="large">
            {t('how_it_works_block.view_collection')}
          </GuideButton>
        </TextWrap>
        <RotatedCardWrap>{nftInfoValues}</RotatedCardWrap>
      </WorkArticle>
    );
  }

  const currentGuideLocale = `how_it_works_block.guide_info.${current.localeValue}` as const;
  return (
    <WorkArticle>
      <TextWrap>
        <H4>{t(`${currentGuideLocale}.title`)}</H4>
        <P fontFamily="primary" size="md" align="left">
          {t(`${currentGuideLocale}.desc`)}
        </P>
      </TextWrap>

      <RotatedCardWrap>
        <FullImg src={current.img} alt={currentGuideLocale} />
      </RotatedCardWrap>
    </WorkArticle>
  );
};

export default Guides;
