export const IMAGE_URL = {
  Landing: {
    LiquidBestNft: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/NFT/ASIC_500.webp',
    LiquidStartGPU: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/NFT/LiquidSTART_GPU.webp',
    LiquidPLUS_Container_installed:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/NFT/LiquidPLUS_Container_installed.webp',
    LiquidASIC_BTC: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/NFT/LiquidASIC_BTC.webp',
    Tron: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Landing%20page/TRON.png',
    ExclusiveFeatures:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/Exclusive%20features.webp',
    ConstantUpdates: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/Constant%20updates.webp',
    LiquidASIC_KAS: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/NFT/LiquidASIC_KAS.webp',
    ApplicationMockup:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/Application%20mockup.webp',
    GrainTexture: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/grain%20texture.webp',
    LinesPattern:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/Lines%20pattern%20background.webp',
    LiquidPattern:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/Liquid%20Pattern%20background.webp',
    Con1: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/coin-1.webp',
    Coin2: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/coin-2.webp',
    Coin3: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Landing%20page/coin-3.webp',
  },
  Cart: {
    checkEmail: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Cart/check-email.webp',
    freeEnergy: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Cart/free-energy.webp',
  },
  MarketPlace: {
    energy: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/Energy.webp',
    PlusContainer:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/PLUS_Container.webp',
    Hashrate: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Hashrate.webp',
    EnergyConsumption:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Energy%20consumption.webp',
    Durability: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Durability.webp',
    EnergyExpenditure:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Energy%20expenditure.webp',
    about_banner_plus_container_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_plus_container_vertical.webp',
    about_banner_plus_container_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_plus_container_horizontal.webp',
    PlusRack: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/PLUS_Rack.webp',
    about_banner_plus_rack_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_plus_rack_vertical.webp',
    about_banner_plus_rack_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_plus_rack_horizontal.webp',
    PlusRig: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/PLUS_Rig.webp',
    about_banner_plus_rig_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_plus_rig_vertical.webp',
    about_banner_plus_rig_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_plus_rig_horizontal.webp',
    START_GPU:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/START_GPU.webp',
    about_banner_start_gpu_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_start_gpu_vertical.webp',
    about_banner_start_gpu_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_start_gpu_horizontal.webp',
    START_ASIC:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/START_ASIC.webp',
    about_banner_start_asic_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_start_asic_vertical.webp',
    about_banner_start_asic_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_start_asic_horizontal.webp',
    ASIC_KASPA:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/ASIC_KASPA.webp',
    about_banner_asic_kas_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_asic_kas_vertical.webp',
    about_banner_asic_kas_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_asic_kas_horizontal.webp',
    ASIC: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/ASIC.webp',
    about_banner_asic_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_asic_vertical.webp',
    about_banner_asic_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_asic_horizontal.webp',
    ASIC_PRO: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/ASIC_PRO.webp',
    about_banner_asic_pro_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_asic_pro_vertical.webp',
    about_banner_asic_pro_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_asic_pro_horizontal.webp',
    ASIC_PRO_RACK:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/ASIC_PRO_RACK.webp',
    about_banner_asic_prorack_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_asic_prorack_vertical.webp',
    about_banner_asic_prorack_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_asic_prorack_horizontal.webp',
    PLUS_GPU: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Still%20Floor/PLUS_GPU.webp',
    about_banner_plus_gpu_vertical:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_plus_gpu_vertical.webp',
    about_banner_plus_gpu_horizontal:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/about/about_banner_plus_gpu_horizontal.webp',
    MP_mainbanner: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/MP_mainbanner.webp',
    Plus_collection_banner:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/Plus_collection_banner.webp',
    Plus_collection_banner_mobile:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/Plus_collection_banner_mobile.webp',
    Start_collection_banner:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/Start_collection_banner.webp',
    Start_collection_banner_mobile:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/Start_collection_banner_mobile.webp',
    Asic_collection_banner:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/Asic_collection_banner.webp',
    Asic_collection_banner_mobile:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Banners/Asic_collection_banner_mobile.webp',
    Reward: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Reward.webp',
    Flash: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/Flash.webp',
  },
  Race: {
    LiquidPLUS_Rig_installed:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/NFT/LiquidPLUS_Rig_installed.webp',
    LiquidPLUS_Rack_installed:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/NFT/LiquidPLUS_Rack_installed.webp',
    LiquidPLUS_Container_installed:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/NFT/LiquidPLUS_Container_installed.webp',
    NewSeasonBannerBackground:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/The%20race/New%20season%20banner%20background.webp',
    NewSeasonBannerBackgroundMobile:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/The%20race/New%20season%20banner%20background_mobile.webp',
  },
  Error: {
    Error404: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Additional%20pages/404.webp',
    Error500: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Additional%20pages/500.webp',
    ErrorBoundary: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Additional%20pages/Error%20boundary.webp',
    TRON_NFT_Required:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Additional%20pages/TRON%20NFT%20required.webp',
    Tech_rack: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Additional%20pages/3d_tech_rack.webp',
    empty: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Additional%20pages/empty.webp',
  },
  Liquidation: {
    Banners_Desktop:
      'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Liquidation/Banners/free_box_desktop.jpg',
    Banners_Mobile: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Liquidation/Banners/free_box_mobile.jpg',
  },

  Profile: {
    Profile_send_coin_right_side_image:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Profile/Liquid%202.0_Profile_send_coin-right_side_image.webp',
    Profile_withdraw_coin_right_side_image:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Profile/Liquid%202.0_Profile_withdraw_coin-right_side_image.webp',
    Profile_link_wallet_tronlink_image:
      'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Profile/Wallet/Tronlink_wp.png',
    Profile_link_wallet_vault_image:
      'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Profile/Wallet/LiquidWallet_wp.png',
  },
} as const;

export const VIDEO_URL = {
  Landing: {
    Start_Demo:
      'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Landing%20page/Race%20for%20the%20Container%20background%20video.mp4',
  },
  MarketPlace: {
    ContainerPlus:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/CONTAINER_PLUS.webm',
    ContainerPlus_OFFER:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/CONTAINER%20PLUS.webm',
    RACK_PLUS:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/RACK_PLUS.webm',
    RACK_PLUS_OFFER: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/RACK%20PLUS.webm',
    RIG_PLUS:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/RIG_PLUS.webm',
    RIG_PLUS_OFFER: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/RIG%20PLUS.webm',
    GPU_START:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/GPU_START.webm',
    GPU_START_OFFER: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/GPU%20START.webm',
    ASIC_START:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/ASIC_START.webm',
    ASIC_START_OFFER: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/ASIC%20START.webm',
    ASIC_KAS:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/ASIC_KAS.webm',
    ASIC_KAS_OFFER: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Marketplace/NFTs/ASIC%20KAS.mp4',
    ASIC: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/ASIC.webm',
    ASIC_OFFER: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Marketplace/NFTs/ASIC.mp4',
    ASIC_PRO:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/ASIC_PRO.webm',
    ASIC_PRO_OFFER: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Marketplace/NFTs/ASIC%20PRO.mp4',
    ASIC_PRO_RACK:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/ASIC_PRO_RACK.webm',
    ASIC_PRO_RACK_OFFER:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/ASIC%20PRO%20RACK.webm',
    GPU_PLUS:
      'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/Preview%20covers/GPU_PLUS.webm',
    GPU_PLUS_OFFER: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Marketplace/NFTs/GPU%20PLUS.webm',
  },
  Profile: {
    Change_email: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Cart/email.mp4',
    Change_username: 'https://storage.googleapis.com/liquid-bucket/Liquid%201.5/Cart/username.mp4',
    KYC: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/Sign%20In%20%26%20Sign%20up/liquid_mining.mp4',
  },
} as const;
