import { styled } from 'styled-components';
import media from 'theme/media';

export const TabWrapper = styled.div`
  & .swiper {
    padding: 0 94px;

    @media (max-width: ${media.laptop}px) {
      padding: 0 60px;
    }

    @media (max-width: ${media.tablet}px) {
      padding: 0 40px;
    }

    @media (max-width: ${media.tablet}px) {
      padding: 0 16px;
    }
  }

  & .swiper-slide {
    width: fit-content !important;
  }
`;
